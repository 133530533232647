.info-line{
    &__text{
      display: block;
      background-color: #d9f0ff;
      padding: 20px;
    }

  &__name{
    padding: 10px 20px;
    font-size: 12px;
    color: #c1c1c1;

    a{
      font-size: 12px;
      color: #c1c1c1;
      text-decoration: none;
    }
  }
}