.comment{

  display: flex;

  &_margin-bottom{
    margin-bottom: 20px;
  }

  &_answer{
    margin-left: 50px;
  }

  &__img{
    border-radius: 50px;
    width: 50px;
  }

  &__avatar{
    margin-right: 10px;
    min-width: 50px;
  }

  &__container{

  }

  &__name{
    font-size: 12px;
    color: #c1c1c1;

    a{
      font-size: 12px;
      color: #c1c1c1;
      text-decoration: none;
    }
  }

  &__text{

  }
}