.file-list{

  &_comment{
    margin-left: 60px;
  }


  &__img{
    max-width: 100%;
  }
}