form{
  .radio-list-icon{
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    li{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 6px;
      label{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 6px;
      }

      input{
        margin: 10px;
      }
    }
  }

  button{
    background-color: black;
    color: #fff;
    padding: 10px 20px;
    font-family: 'AnonymousPro-Regular', monospace;

    &:hover{
      background-color: black + 60;
    }
  }

  .textarea-project{

    .column:first-child{
      padding-right: 0px;
    }
    .column:last-child{
      padding-left: 0px;
    }

    textarea{
      height: 130px;
      width: 100%;
      font-family: 'AnonymousPro-Regular', monospace;
      padding: 10px;
      border: 1px solid silver;
      border-right: none;
      resize: none;
      font-size: 14px;
      line-height: 18px;

      &::placeholder {
        color: silver;
        opacity: 1;
      }
    }

    .info{
      height: 130px;
      width: 100%;
      font-family: 'AnonymousPro-Regular', monospace;
      padding: 10px;
      border: 1px solid silver;
      border-left: none;
      text-align: right;
      font-size: 14px;
      color: silver;


      ul{
        display: none;

        margin: 0px;
        padding: 0px;
        li{
          margin: 0px;
          padding: 0px;

        }
      }
    }
  }
}

.account-info{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 10px 10px;
  background-color: #ecf8ff;

  p{
    margin: 0 5px;
  }
}

.account-section{

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 10px 0px;

  .account{
    border: 1px solid silver;
    width: 486px;
    margin-right: 5px;
    margin-bottom: 5px;

    &:nth-child(2n+2){
      margin-right: 0px;
    }


    .header{
      color: #fff;
      padding: 10px;
      cursor: pointer;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-start;

      svg {
        float: left;
        margin: 0px 5px;
      }

      span{
        margin: 0px 5px;
        float: left;
        display: inline-block;
        overflow: hidden;

        &.caption{
          color: #8e8e8e;
        }

      }
    }

    .content{

      p{
        overflow: auto;
      }

      &.loaded {
        padding: 10px;
      }

      svg{
        cursor: pointer;
        color: #A9A9B3;
      }

      .caption{
        color: #A9A9B3;
      }
    }
  }
}

.animate-shake{
  color: red;
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}