.row .collapse{
  .column{
    padding: 0px;
  }
}

body,h1,h2,h3,h4{
  font-family: 'AnonymousPro-Regular', monospace;

}

h2{
  margin: 10px 0px;
}

input,select,textarea{
  outline : none;
}

hr{
  border: none;
  border-bottom: 1px solid #A9A9B3;
  margin: 20px 0px;
}

p{
  line-height: 26px;
}

form label{
  display: inline-block;
  padding: 0px 5px;
  cursor: pointer;
}

.a-button{
  display: inline-block;
  background-color: #000;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;

  &:hover{
    background-color: black + 60;
  }
}

.color-red{
  color: red;
}

.color-green{
  color: #4ab707;
}


.gmail_quote{
  display: none;
}

textarea{
  height: 150px;
}

*[data-self-ajax]{
  cursor: pointer;
}