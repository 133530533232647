.link{
  &_black{
    color: #000;

    &:visited{
      color: #000;
    }
  }

  &_decor{
    text-decoration: none;
  }
}